<template>
  <div class="dashboard">
    <h1 class="header-color" style="text-align: left; padding: 10px">
      <font-awesome-icon
        class="header-color"
        size="1x"
        :icon="['fas', 'tasks']"
      />
      Continue Issuance
    </h1>
    <div class="government">
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <b-card class="dashboard-card">
              <div class="container-fluid">
                <p                 
                >
                <b> <h4>
                  InfraPX Bonds </h4></b>
                </p>
                <div class="row flex-row flex-nowrap">
                  <Grid
                    ref="grid"
                    :style="{ height: '900px', width: '100%' }"
                    :data-items="gridData"
                    :selected-field="selectedField"
                    :columns="staticColumns"
                    @rowclick="onRowClick"
                    :sortable="sortable"
                    :filterable="filterable"
                    :groupable="groupable"
                    :reorderable="reorderable"
                    :pageable="{ buttonCount: 4, pageSizes: true }"
                    :skip="skip"
                    :take="take"
                    :sort="sort"
                    :group="group"
                    :filter="filter"
                    @datastatechange="dataStateChange"
                  >
                  </Grid>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import { Map, MapLayer, MapMarker } from "@progress/kendo-map-vue-wrapper";
import { Grid } from "@progress/kendo-vue-grid";
import { process } from "@progress/kendo-data-query";
export default {
  name: "continueIssuance",
  components: {
    Grid: Grid,
  },
  computed: {
    items() {
      return this.$store.state.bondData.filter(
        (item) => item.status.toUpperCase() === "PENDING"
      );
    },
    selectedItem() {
      if (this.items)
        return this.items.find((item) => item.bond_id === this.selectedID);
      else {
        return null;
      }
    },
    totalAmount() {
      var formatter = new Intl.NumberFormat("en-CA", {
        style: "currency",
        currency: "CAD",
      });
      return formatter
        .format(
          Math.round(this.items.reduce((a, b) => a + (b["amount"] || 0), 0))
        )
        .replace(/(\.|,)00$/g, "");
    },
    totalYieldSpend() {
      return "15K";
    },
    numberOfBonds() {
      return this.items.length;
    },
  },
  data: function () {
    return {
      gridData: [],
      sortable: true,
      filterable: true,
      groupable: true,
      reorderable: true,
      skip: 0,
      take: 20,
      sort: [{ field: "owner", dir: "desc" }],
      group: [{ field: "owner" }],
      filter: null,
      selectedField: "selected",
      selectedID: 1,
      staticColumns: [
        { field: "owner", title: "Municipality", width: "250px" },
        { field: "status", title: "Status", width: "200px" },
        { field: "asset_id", title: "Asset ID", width: "250px" },
        { field: "asset_class", title: "Asset Class", width: "250px" },
        { field: "descriptor", title: "Project", width: "250px" },
        {
          field: "amount",
          title: "Amount (CAD)",
          filter: "numeric",
          width: "250px",
          cell: this.amountCellFunction,
        },
        {
          field: "yield",
          title: "Yield",
          filter: "numeric",
          width: "200px",
          cell: this.yieldCellFunction,
        },
        {
          field: "term",
          title: "Term (month)",
          filter: "numeric",
          width: "200px",
        },
        {
          field: "maturity",
          title: "Maturity",
          filter: "numeric",
          width: "200px",
        },
        {
          field: "date_added",
          title: "Added",
          filter: "numeric",
          width: "200px",
        },
      ],
      series: [],

      labelTemplate: "#= category # - #= kendo.format('{0:P}', percentage)#",
      tooltipTemplate: "#= category # - #= kendo.format('{0:P}', percentage) #",
    };
  },
  created: function () {
    const dataState = {
      skip: this.skip,
      take: this.take,
      sort: this.sort,
      group: this.group,
    };
    this.gridData = process(this.items, dataState);

    let uniqueClasses = [
      ...new Set(this.items.map((item) => item.asset_class)),
    ];
    var returnData = [];
    uniqueClasses.forEach((item) =>
      returnData.push({
        category: item,
        value: this.items.filter((element) => element.asset_class == item)
          .length,
      })
    );
    this.series.push({ type: "pie", data: returnData });
  },
  methods: {
    yieldCellFunction: function (h, tdElement, props, listeners) {
      return h("td", {}, [this.formatYield(props.dataItem.yield)]);
    },
    amountCellFunction: function (h, tdElement, props, listeners) {
      return h("td", {}, [this.formatNumber(props.dataItem.amount)]);
    },
    onRowClick(event) {
      this.selectedID = event.dataItem.bond_id;
      if (this.selectedID !== undefined) {
        this.$router.push({
          name: "Issuance",
          params: { id: this.selectedID },
        });
      }
    },
    getData: function () {
      let dataState = {
        take: this.take,
        skip: this.skip,
        filter: this.filter,
        sort: this.sort,
      };

      this.gridData = process(this.items, dataState);
    },
    createAppState: function (dataState) {
      this.take = dataState.take;
      this.skip = dataState.skip;
      if (dataState.group) {
        dataState.group.map((group) => (group.aggregates = this.aggregates));
      }
      this.group = dataState.group;
      this.filter = dataState.filter;
      this.sort = dataState.sort;
    },
    dataStateChange(event) {
      this.createAppState(event.data);
      this.gridData = process(this.items, {
        skip: this.skip,
        take: this.take,
        sort: this.sort,
        filter: this.filter,
        group: this.group,
      });
    },
    expandChange: function (event) {
      event.dataItem[event.target.$props.expandField] = event.value;
    },
    pageChangeHandler: function (event) {
      this.skip = event.page.skip;
      this.take = event.page.take;
    },
    columnReorder: function (options) {
      this.columns = options.columns;
    },
  },
};
</script>


